import React, { useState } from "react"
import { Helmet } from "react-helmet"
import Navbar, { TextBrand } from "../components/Navbar"
import screenshot from "../images/screenshot.png"
import "@fortawesome/fontawesome-free/css/all.css"
import "../mono.scss"

const UniversitiesPage = () => {
  return (
    <>
      <Helmet>
        <title>Jeffrey - Study Buddy</title>
        <meta
          name="description"
          content="Never miss an assignment and connect with your university on Jeffrey."
        />
      </Helmet>
      <Navbar brand={TextBrand} />
      <section className="section" style={{ paddingTop: 0 }}>
        <div className="container">
          <div className="columns">
            <div className="column is-6">
              <h1 className="title is-size-1" style={{ marginTop: "20%" }}>
                Meet Jeffrey.
              </h1>
              <h3 className="is-size-3">
                Jeffrey connects your students to your campus on a personal
                basis. Make finding information easier for students and save on
                administrative time.
              </h3>
              <a
                className="button is-medium is-primary is-rounded"
                href="#faqs"
                style={{ margin: "20px auto" }}
              >
                <span>Learn More</span>
              </a>
            </div>
          </div>
        </div>
      </section>
      <section className="section" style={{ paddingTop: 0 }}>
        <div className="container">
          <div className="columns">
            <div className="column is-8">
              <h1 className="title is-size-1 is-title">
                Information at students' fingertips.
              </h1>
              <h3 className="is-size-3">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin
                tempor varius leo a feugiat. Cras a elit a elit scelerisque
                tempor varius a nisl. Nunc vitae erat varius, suscipit ipsum ut,
                consectetur mauris. Fusce risus ante, mollis vel pulvinar ut,
                ullamcorper a nibh. Phasellus scelerisque nec justo a blandit.
                In hac habitasse platea dictumst. Donec lectus justo, aliquet
                vitae condimentum in, condimentum sed ipsum. Etiam faucibus
                ligula a consequat sollicitudin. Nulla facilisi. Donec nisi
                orci, mattis vel mollis sit amet, hendrerit in erat. Nulla
                facilisi.{" "}
              </h3>
            </div>
            <div className="column">
              <img
                src={screenshot}
                className="column-screenshot image-shadow"
                alt="Jeffrey - A virtual personal assistent."
              />
            </div>
          </div>
        </div>
      </section>
      <section className="section" style={{ paddingTop: 20 }}>
        <div className="container">
          <div className="columns">
            <div className="column is-4">
              <img
                src={screenshot}
                className="column-screenshot image-shadow"
                alt="Jeffrey - A virtual personal assistent."
              />
            </div>
            <div className="column is-8">
              <h1 className="title is-size-1 is-title">
                Keep engaged on campus.
              </h1>
              <h3 className="is-size-3">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin
                tempor varius leo a feugiat. Cras a elit a elit scelerisque
                tempor varius a nisl. Nunc vitae erat varius, suscipit ipsum ut,
                consectetur mauris. Fusce risus ante, mollis vel pulvinar ut,
                ullamcorper a nibh. Phasellus scelerisque nec justo a blandit.
                In hac habitasse platea dictumst. Donec lectus justo, aliquet
                vitae condimentum in, condimentum sed ipsum. Etiam faucibus
                ligula a consequat sollicitudin. Nulla facilisi. Donec nisi
                orci, mattis vel mollis sit amet, hendrerit in erat. Nulla
                facilisi.{" "}
              </h3>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default UniversitiesPage
